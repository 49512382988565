/* Brand- Motto */
@font-face {
  font-family: Monument Grotesk Reg;
  font-display: swap;
  src: url('/modules/assets/fonts/monument-grotesk/regular.woff2') format('woff2');
}

/* Themes */

.theme-ua {
  --color-primary: 41 71 66;
  --color-primary-alt: 41 71 66;
  --color-secondary: 166 80 48;
  --color-secondary-alt: 166 80 48;
  --color-brand: 41 71 66;
  --color-brand-alt: 41 71 66;
  --font-headline: 'Monument Grotesk Reg';
  --color-bg-light: 228 242 240;
  --color-bg-dark: 41 71 66;
  --color-text-heading: var(--color-primary);
  --heading-spacing-preset: normal;

  /* property-ui theme */
  --color-ua-accent: 41 71 66;

  /* Image Headliner styles */
  --image-headliner-control-color-default: var(--color-text-inverse);
}

.theme-ua h1,
.theme-ua h2,
.theme-ua h3,
.theme-ua h4,
.theme-ua h5,
.theme-ua h6 {
  font-variant-ligatures: common-ligatures;
}

.theme-ua {
  /* Design System font variables */
  --ds-headline-font-family: 'Monument Grotesk Reg';

  --color-motto-green: #294746;
  --color-motto-mint: #e4f2f0;

  .surface-base,
  .surface-alternate {
    --ds-color-headline: var(--color-motto-green);
    --ds-color-interactive: var(--color-motto-green);
    --ds-color-interactive-hover: var(--color-neutral-900);
  }

  .surface-alternate {
    --ds-color-background: var(--color-motto-mint);
  }

  .surface-inverse {
    --ds-color-background: var(--color-motto-green);
    --ds-color-headline: var(--color-motto-mint);
    --ds-color-interactive-hover: var(--color-motto-mint);
  }
}
