/* Brand- Tapestry */
@font-face {
  font-family: Web National Medium;
  font-display: swap;
  src: url('/modules/assets/fonts/web-national-medium/national-medium.woff2') format('woff2');
}

@font-face {
  font-family: Web National Light;
  font-display: swap;
  src: url('/modules/assets/fonts/web-national-light/national-light.woff2') format('woff2');
}

@font-face {
  font-family: Web Basel Classic Regular;
  font-display: swap;
  src: url('/modules/assets/fonts/web-basel-classic-regular/basel-classic-regular.woff2')
    format('woff2');
}

.theme-up {
  --color-primary: 40 0 113;
  --color-primary-alt: 40 0 113;
  --color-secondary: 181 37 85;
  --color-secondary-alt: 181 37 85;
  --color-brand: 40 0 113;
  --color-brand-alt: 40 0 113;
  --font-headline: 'Web Basel Classic Regular';
  --font-sans: 'Web National Light';
  --color-text-heading: var(--color-primary);
  --heading-transform-preset: uppercase;
  --heading-spacing-preset: normal;

  /* property-ui theme */
  --color-up-accent: 40 0 113;

  /* Image Headliner styles */
  --image-headliner-control-color-default: var(--color-text-inverse);

  /* Horizontal Tabbed styles */
  --horizontal-tabbed-list-description-mb: 3rem;
  /* dynamic grid */
  --dynamic-grid-header-text-transform: var(--heading-transform-preset);
  --dynamic-grid-description-margin-bottom: 3rem;
  --dynamic-grid-wom-description-margin-bottom: 3rem;
  --dynamic-grid-item-wom-header-text-transform: var(--heading-transform-preset);
}

.theme-up {
  font-family: 'Web National Light';
}

.theme-up strong {
  font-family: 'Web National Medium';
}

.theme-up .searchWidgetBrand {
  font-family: 'Web National Medium';
}

.theme-up header {
  font-family: 'Proxima Nova';
}

.theme-up h1,
.theme-up h2,
.theme-up h3,
.theme-up h4,
.theme-up h5,
.theme-up h6 {
  font-variant-ligatures: common-ligatures;
}

@screen lg {
  .theme-up {
    .bg-img-overlay {
      background: url('/modules/assets/images/tapestry-bg13.svg') left 7rem bottom 0rem no-repeat,
        url('/modules/assets/images/tapestry-bg14.svg') right 0rem bottom -3rem no-repeat,
        url('/modules/assets/images/tapestry-bg15.svg') left 1.5rem top 18rem no-repeat,
        url('/modules/assets/images/tapestry-bg16.svg') right 7rem top 0rem no-repeat;
      background-size: 7rem, 9rem, 7rem, 7rem;
    }
  }

  .theme-up .brandCarousel .bg-img-overlay {
    background: url('/modules/assets/images/tapestry-bg13.svg') left 7rem bottom 0rem no-repeat,
      url('/modules/assets/images/tapestry-bg14.svg') right -4.5rem bottom 0rem no-repeat,
      url('/modules/assets/images/tapestry-bg15.svg') left 1.5rem top 18rem no-repeat,
      url('/modules/assets/images/tapestry-bg16.svg') right 3rem top 0rem no-repeat;
    background-size: 7rem, 9rem, 7rem, 7rem;
  }

  .theme-up .halfAndHalf .half-and-half-even-0 .container {
    background: url('/modules/assets/images/tapestry-bg9.svg') right 10rem bottom 0rem no-repeat,
      url('/modules/assets/images/tapestry-bg11.svg') right 2rem top 0rem no-repeat;
    background-size: 7rem, 7rem;
  }

  .theme-up .halfAndHalf .half-and-half-even-0 {
    background-color: transparent;
  }

  .theme-up .halfAndHalf .half-and-half-even-2 .container {
    background: url('/modules/assets/images/tapestry-bg10.svg') right bottom no-repeat,
      url('/modules/assets/images/tapestry-bg11.svg') left 1.5rem top 13rem no-repeat,
      url('/modules/assets/images/tapestry-bg12.svg') right 5rem top 0rem no-repeat;
    background-size: 7rem, 7rem, 7rem;
  }

  .theme-up .halfAndHalf .half-and-half-even-2 {
    background-color: transparent;
  }

  .theme-up .threeSixNineGrid .bg-img-overlay,
  .theme-up .threeSixNineWOMGrid .bg-img-overlay,
  .theme-up .fourXGrid .bg-img-overlay,
  .theme-up .fourXWOMGrid .bg-img-overlay {
    background: url('/modules/assets/images/tapestry-bg8.svg') left top no-repeat,
      url('/modules/assets/images/tapestry-bg9.svg') right 0% top 9rem no-repeat;
    background-size: 7rem, 9rem;
    max-width: calc(theme(screens.2xl) - 4rem);
    width: calc(100% - 4rem);
  }

  .theme-up .masthead .bg-img-overlay {
    background-image: url('/modules/assets/images/tapestry-bg7.svg');
    max-width: 85rem;
    background-size: 6rem;
    background-position: right 0% bottom 4.5rem;
    width: calc(100% - 4rem);
  }
}

@media (min-width: theme(screens.2xl)) {
  .theme-up .masthead .bg-img-overlay {
    @apply mx-auto;
  }
}

.theme-up {
  /* Design System font variables */
  --ds-body-font-family: 'Web National Light';
  --ds-headline-font-family: 'Web Basel Classic Regular';

  --color-tapestry-blue: #280071;
  --color-tapestry-orange: #e8a12d;
  --color-tapestry-pink: #8f1a95;
  --color-tapestry-light-gray: #d2cece;

  .surface-base,
  .surface-alternate {
    --ds-color-headline: var(--color-tapestry-blue);
    --ds-color-interactive: var(--color-tapestry-blue);
    --ds-color-interactive-hover: var(--color-tapestry-pink);
  }

  .surface-alternate {
    --ds-color-border: var(--color-neutral-400);
    --ds-color-background: var(--color-tapestry-light-gray);
  }

  .surface-inverse {
    --ds-color-background: var(--color-tapestry-blue);
    --ds-color-interactive-hover: var(--color-tapestry-light-gray);
  }

  .btn--base {
    @apply font-bold;
  }

  .heading--base {
    @apply uppercase font-extrabold;
  }
}
