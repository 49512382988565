/* Brand- Conrad */
@font-face {
  font-family: Avenir Next;
  font-weight: 200;
  font-display: swap;
  src: url('/modules/assets/fonts/avenir-next/light.woff2') format('woff2');
}

@font-face {
  font-family: Avenir Next;
  font-weight: 400;
  font-display: swap;
  src: url('/modules/assets/fonts/avenir-next/regular.woff2') format('woff2');
}

@font-face {
  font-family: Avenir Next;
  font-weight: 600;
  font-display: swap;
  src: url('/modules/assets/fonts/avenir-next/regular.woff2') format('woff2');
}

@font-face {
  font-family: Avenir Next;
  font-weight: 700;
  font-display: swap;
  src: url('/modules/assets/fonts/avenir-next/bold.woff2') format('woff2');
}

@font-face {
  font-family: Avenir Next;
  font-weight: 800;
  font-display: swap;
  src: url('/modules/assets/fonts/avenir-next/bold.woff2') format('woff2');
}

.theme-ch {
  --color-primary: 0 0 0;
  --color-primary-alt: 0 0 0;
  --color-secondary: 169 139 95;
  --color-secondary-alt: 169 139 95;
  --color-brand: 0 0 0;
  --color-brand-alt: 0 0 0;
  --font-sans: 'Avenir Next';
  --font-headline: 'Avenir Next';
  --color-text-heading: var(--color-primary);
  --heading-spacing-preset: normal;

  /* property-ui theme */
  --color-ch-accent: 0 0 0;
}

.theme-ch .font-headline {
  font-weight: 800;
  text-transform: uppercase;
}

.theme-ch h1,
.theme-ch h2,
.theme-ch h3,
.theme-ch h4,
.theme-ch h5,
.theme-ch h6 {
  font-variant-ligatures: common-ligatures;
}

.theme-ch {
  /* Design System font variables */
  --ds-body-font-family: 'Avenir Next';
  --ds-headline-font-family: 'Avenir Next';
  --ds-headline-font-weight: 800;

  --color-conrad-gold: #a98b5f;
  --color-conrad-snow: #f4f2f3;
  --color-conrad-sand: #f0e5d8;

  .surface-base,
  .surface-alternate {
    --ds-color-border: var(--color-neutral-400);
    --ds-color-interactive: var(--color-neutral-950);
    --ds-color-headline: var(--color-neutral-950);
  }

  .surface-alternate {
    --ds-color-background: var(--color-conrad-snow);
  }

  .surface-inverse {
    --ds-color-background: var(--color-conrad-sand);
    --ds-color-background-disabled: var(--color-neutral-100);
    --ds-color-border: var(--color-neutral-500);
    --ds-color-border-input: var(--color-neutral-500);
    --ds-color-headline: var(--color-neutral-900);
    --ds-color-text: var(--color-neutral-950);
    --ds-color-text-muted: var(--color-neutral-600);
    --ds-color-text-disabled: var(--color-neutral-400);
    --ds-color-interactive: var(--color-neutral-900);
    --ds-color-interactive-contrast: var(--color-white);
    --ds-color-interactive-hover: var(--color-neutral-600);
    --ds-color-interactive-hover-contrast: var(--color-white);
    --ds-color-interactive-accent: var(--ds-color-interactive);
    --ds-color-interactive-accent-contrast: var(--ds-color-interactive-contrast);
    --ds-color-interactive-accent-hover: var(--ds-color-interactive-hover);
    --ds-color-interactive-accent-hover-contrast: var(--ds-color-interactive-hover-contrast);
    --ds-button-disabled-foreground: var(--color-neutral-400);
    --ds-button-disabled-background: var(--color-neutral-100);
    --ds-button-disabled-border: var(--color-neutral-100);
  }

  .btn--base {
    @apply font-bold;
  }

  .heading--base {
    @apply uppercase font-extrabold;
  }
}
