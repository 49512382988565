/* Brand- Cobrand */

/* ============================= TYPOGRAPHY =============================*/

@font-face {
  font-family: Biennale;
  font-weight: 400;
  font-display: swap;
  src: url('/modules/assets/fonts/biennale/regular.woff2') format('woff2');
}

@font-face {
  font-family: Biennale;
  font-weight: 200;
  font-display: swap;
  src: url('/modules/assets/fonts/biennale/light.woff2') format('woff2');
}

@font-face {
  font-family: Biennale;
  font-weight: 500;
  font-display: swap;
  src: url('/modules/assets/fonts/biennale/medium.woff2') format('woff2');
}

@font-face {
  font-family: Biennale;
  font-weight: 600;
  font-display: swap;
  src: url('/modules/assets/fonts/biennale/semiBold.woff2') format('woff2');
}

@font-face {
  font-family: Biennale;
  font-weight: 700;
  font-display: swap;
  src: url('/modules/assets/fonts/biennale/bold.woff2') format('woff2');
}

@font-face {
  font-family: Hilton Serif;
  font-weight: 400;
  font-display: swap;
  src: url('/modules/assets/fonts/hilton-serif/Hilton-SerifRegular.woff2') format('woff2');
}

@font-face {
  font-family: Hilton Serif;
  font-weight: 500;
  font-display: swap;
  src: url('/modules/assets/fonts/hilton-serif/Hilton-SerifMedium.woff2') format('woff2');
}

/* ============================= THEMING ============================= */

.theme-cb {
  --color-primary: 5 130 112;
  --color-primary-alt: 0 84 80;
  --color-secondary: 0 47 97;
  --color-secondary-alt: 0 22 59;
  --color-tertiary: 0 114 147;
  --color-brand-alt: 10 57 113; /* global primary-alt */
  --color-bg-light: 240 233 230;
  --font-headline: 'Hilton Serif';
  --color-text-heading: var(--color-primary);
  --heading-spacing-preset: normal;

  /* Image Headliner styles */
  --image-headliner-control-color-default: var(--color-text-inverse);

  /* Design System font variables */
  --ds-body-font-family: 'Biennale';
  --ds-headline-font-family: 'Hilton Serif';
}

.theme-cb h1,
.theme-cb h2,
.theme-cb h3,
.theme-cb h4,
.theme-cb h5,
.theme-cb h6 {
  font-variant-ligatures: common-ligatures;
}
