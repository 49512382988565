/* Brand- NoMad Hotels */

/* ============================= TYPOGRAPHY ============================= */

@font-face {
  font-family: Montserrat;
  font-weight: 500;
  font-display: swap;
  src: url('/modules/assets/fonts/montserrat/medium.woff2') format('woff2');
}

@font-face {
  font-family: 'adobe-caslon-pro';
  src: url('https://use.typekit.net/af/3cbd9b/000000000000000000012d68/27/l?primer=7cdcb44be4a7db8877ffa5c0007b8dd865b3bbc383831fe2ea177f62257a9191&fvd=n4&v=3')
      format('woff2'),
    url('https://use.typekit.net/af/3cbd9b/000000000000000000012d68/27/d?primer=7cdcb44be4a7db8877ffa5c0007b8dd865b3bbc383831fe2ea177f62257a9191&fvd=n4&v=3')
      format('woff'),
    url('https://use.typekit.net/af/3cbd9b/000000000000000000012d68/27/a?primer=7cdcb44be4a7db8877ffa5c0007b8dd865b3bbc383831fe2ea177f62257a9191&fvd=n4&v=3')
      format('opentype');
  font-display: auto;
  font-style: normal;
  font-weight: 400;
  font-stretch: normal;
}

@font-face {
  font-family: 'adobe-caslon-pro';
  src: url('https://use.typekit.net/af/ed9e57/000000000000000000012d65/27/l?subset_id=2&fvd=n7&v=3')
      format('woff2'),
    url('https://use.typekit.net/af/ed9e57/000000000000000000012d65/27/d?subset_id=2&fvd=n7&v=3')
      format('woff'),
    url('https://use.typekit.net/af/ed9e57/000000000000000000012d65/27/a?subset_id=2&fvd=n7&v=3')
      format('opentype');
  font-display: auto;
  font-style: normal;
  font-weight: 700;
  font-stretch: normal;
}

/* ============================= THEMING ============================= */
.theme-nd {
  --color-primary: 0 0 0;
  --color-primary-alt: 68 68 68;
  --color-secondary: 255 255 255;
  --color-secondary-alt: 204 204 204;
  --color-tertiary: 246 246 239;
  --color-quarternary: 41 41 41;
  --color-brand: 0 0 0;
  --color-bg-light: 246 246 239;
  --color-bg-dark: 0 0 0;
  --font-headline: 'adobe-caslon-pro';
  --font-headline-brand: 'adobe-caslon-pro';
  --font-sans: 'adobe-caslon-pro';
  --font-serif: 'adobe-caslon-pro';
  --font-mono: 'Montserrat';

  /* property-ui theme */
  --color-nd-accent: 0 0 0;

  /* Heading Text presets */
  --color-text-heading: var(--color-primary);
  --heading-spacing-preset: 0em;
  --font-headline-weight: 400;
  --heading-style-preset: italic;
  /* Main Nav Presets*/
  --nav-item-font-family: var(--font-mono);
  --nav-main-link-font-family: var(--font-mono);
  --nav-main-link-font-weight: 500;
  --nav-item-dark-active-text-color: var(--color-primary);
  --nav-item-active-bg-dark-color: var(--color-secondary);
  --nav-item-active-border-dark-color: var(--color-primary);
  --user-icon-dark-active-fill-color: var(--color-primary);

  --nav-divider-bottom-padding: 0;
  --nav-divider-top-padding: 0.375rem;
  --nav-popup-margin: 0;
  --nav-item-border-width: 0;

  /* Button styles */
  --btn-base-border-radius: none;
  --btn-base-font-weight: 500;
  --btn-text-transform: uppercase;
  --btn-font-family: var(--font-mono);

  --btn-bg-hover-color-dark: var(--color-primary-alt);
  --btn-text-hover-color-dark: var(--color-text-inverse);

  --btn-text-hover-color-outline: var(--color-primary-alt);
  --btn-border-hover-color-outline: var(--color-primary-alt);
  --btn-bg-hover-color-outline: var(--color-bg);

  --btn-text-hover-color-dark-outline: var(--color-secondary-alt);
  --btn-border-hover-color-dark-outline: var(--color-secondary-alt);
  --btn-bg-hover-color-dark-outline: var(--color-primary);

  --btn-bg-hover-color-dark-solid: var(--color-primary-alt);
  --btn-text-hover-color-dark-solid: var(--color-text-inverse);

  --shop-form-btn-text-hover-color: var(--color-text-inverse);
  --shop-form-btn-border-hover-color: var(--color-primary-alt);
  --shop-form-btn-bg-hover-color: var(--color-primary-alt);

  /* Image Headliner styles */
  --image-headliner-text-color: var(--color-primary);
  --image-headliner-font-weight: 400;
  --image-headliner-link-text-color: var(--color-primary);

  /* Editorial Snippet styles */
  --editorial-snippet-short-description-text-color: var(--color-primary);

  /* Horizontal Tabbed styles */
  --horizontal-tabbed-list-scrollable-border-b-color: var(--color-primary);
  --horizontal-tabbed-list-scrollable-border-b-color-light: var(--color-primary);

  /* Vertical Tabbed styles */
  --vertical-tabbed-accordion-border-color: var(--color-primary);
  --vertical-tabbed-accordion-border-color-light: var(--color-primary);
  --vertical-tabbed-accordion-expanded-bg-color: var(--color-bg-alt);
  --vertical-tabbed-accordion-expanded-bg-color-light: var(--color-bg-alt);
  --vertical-tabbed-accordion-expanded-bg-color-dark: var(--color-quarternary);
  --vertical-tabbed-button-inactive-font-weight: 500;
  --vertical-tabbed-button-inactive-font-family: var(--font-mono);
  --vertical-tabbed-button-inactive-bg: var(--color-bg);
  --vertical-tabbed-button-inactive-border-color: var(--color-primary);
  --vertical-tabbed-button-inactive-bg-light: var(--color-bg-light);
  --vertical-tabbed-button-inactive-border-color-light: var(--color-primary);
  --vertical-tabbed-button-inactive-bg-dark: var(--color-bg-dark);
  --vertical-tabbed-button-inactive-border-color-dark: var(--color-bg);
  --vertical-tabbed-button-inactive-text-color-dark: var(--color-text-inverse);
  --vertical-tabbed-button-inactive-hover-text-color-dark: var(--color-text-disabled);
  --vertical-tabbed-button-active-font-weight: 500;
  --vertical-tabbed-button-active-font-family: var(--font-mono);
  --vertical-tabbed-button-active-bg: var(--color-bg-alt);
  --vertical-tabbed-button-active-border-color: var(--color-primary);
  --vertical-tabbed-button-active-bg-light: var(--color-bg-alt);
  --vertical-tabbed-button-active-border-color-light: var(--color-primary);
  --vertical-tabbed-button-active-bg-dark: var(--color-quarternary);
  --vertical-tabbed-button-active-border-color-dark: var(--color-bg);
  --vertical-tabbed-panel-border-color: var(--color-primary);
  --vertical-tabbed-panel-border-color-light: var(--color-primary);
  --vertical-tabbed-panel-border-color-dark: var(--color-bg);
  /* dynamic grid */
  --dynamic-grid-item-wom-header-font-weight: var(--font-headline-weight);
  /* Slider */
  --slider-gradient-on-color-dark: var(--color-primary);
  --slider-thumb-color-dark: var(--color-primary);
}

@screen lg {
  .theme-nd {
    --dynamic-grid-item-wom-header-font-size: 1.875rem;
    --dynamic-grid-item-wom-header-line-height: 2.25rem;
  }
}

@screen sm {
  .theme-nd {
    /* Patchwork Grid Row */
    --patchwork-grid-row-headline-font-size: 2.25rem;
    --patchwork-grid-row-headline-line-height: 2.5rem;
  }
}

.theme-nd {
  /* Design System font variables */
  --ds-body-font-family: 'adobe-caslon-pro';
  --ds-headline-font-family: 'adobe-caslon-pro';

  --color-nomad-off-white: #f6f6ef;

  .surface-alternate {
    --ds-color-background: var(--color-nomad-off-white);
  }

  .surface-inverse {
    --ds-color-background: var(--color-neutral-950);
  }

  .btn--base {
    @apply uppercase rounded-none font-medium font-mono;
  }
}
