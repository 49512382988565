/* Brand- Canopy */
@font-face {
  font-family: Domaine Extra Bold;
  font-display: swap;
  src: url('/modules/assets/fonts/domaine-display/extrabold.woff2') format('woff2');
}

@font-face {
  font-family: FS Elliot Pro;
  font-display: swap;
  src: url('/modules/assets/fonts/fs-elliot-pro/regular.woff') format('woff');
}

@font-face {
  font-family: FS Elliot Pro;
  font-weight: 700;
  font-display: swap;
  src: url('/modules/assets/fonts/fs-elliot-pro/bold.woff') format('woff');
}

.theme-py {
  --color-primary: 78 78 78;
  --color-primary-alt: 78 78 78;
  --color-secondary: 0 0 0;
  --color-secondary-alt: 0 0 0;
  --color-brand: 254 80 0;
  --color-brand-alt: 254 80 0;
  --font-headline: 'Domaine Extra Bold';
  --color-bg-light: 244 244 244;
  --color-text-heading: var(--color-primary);
  --heading-spacing-preset: normal;

  /* property-ui theme */
  --color-py-accent: 78 78 78;
  --color-py-icon-accent: 254 80 0;

  /* Image Headliner styles */
  --image-headliner-control-color-default: var(--color-text-inverse);
}

.theme-py-tailored {
  /* property-ui theme */
  --color-py-primary: 255 255 255;
  --color-py-primary-contrast: 0 0 0;
  --color-py-secondary: 245 245 245;
  --color-py-secondary-contrast: 0 0 0;
  --color-py-tertiary: 254 80 0;
  --color-py-tertiary-contrast: 0 0 0;
  --color-py-accent: 254 80 0;

  --color-primary: var(--theme-colors-primary-contrast);
  --color-primary-alt: var(--theme-colors-accent);

  /* alerts */
  --alert-bg-color: var(--theme-colors-tertiary);
  --alert-text-color: var(--theme-colors-tertiary-contrast);
  --alert-icon-color: var(--theme-colors-primary-contrast);

  /* buttons */
  /* - base styling */
  --btn-base-border-radius: none;
  --btn-base-font-weight: 400;
  --btn-base-letter-spacing: 0.5px;
  --btn-text-transform: uppercase;

  /* - primary */
  --btn-bg-color: var(--theme-colors-primary-contrast);
  --btn-text-color: var(--theme-colors-primary);
  --btn-bg-hover-color: var(--theme-colors-accent);
  --btn-border-hover-color: var(--btn-bg-hover-color);
  --btn-text-hover-color: var(--btn-bg-color);

  /* - primary outline */
  --btn-border-color-outline: var(--btn-bg-hover-color);
  --btn-text-color-outline: var(--btn-bg-color);
  --btn-text-hover-color-outline: var(--btn-text-color);

  /* - dark */
  --btn-bg-color-dark: var(--btn-text-color);
  --btn-bg-color-dark-solid: var(--btn-bg-color-dark);
  --btn-text-color-dark: var(--btn-bg-color);
  --btn-text-color-dark-solid: var(--btn-text-color-dark);
  --btn-bg-hover-color-dark: var(--transparent);
  --btn-bg-hover-color-dark-solid: var(--btn-bg-hover-color-dark);
  --btn-text-hover-color-dark: var(--btn-text-color);
  --btn-text-hover-color-dark-solid: var(--btn-text-hover-color-dark);

  /* - dark outline */
  --btn-bg-hover-color-dark-outline: var(--btn-text-color);
  --btn-text-hover-color-dark-outline: var(--btn-bg-color);

  /* - shop form */
  --shop-form-btn-text-color: var(--btn-text-color-outline);
  --shop-form-btn-border-color: var(--btn-border-color-outline);
  --shop-form-btn-text-hover-color: var(--btn-text-color);
  --shop-form-btn-bg-hover-color: var(--btn-bg-color);
  --shop-form-btn-border-hover-color: var(--btn-bg-color);
  --shop-form-btn-submit-text-color: var(--btn-text-color);
  --shop-form-btn-submit-bg-color: var(--btn-bg-color);
  --shop-form-btn-submit-border-color: var(--btn-bg-color);
  --shop-form-btn-submit-text-hover-color: var(--btn-text-hover-color);
  --shop-form-btn-submit-bg-hover-color: var(--btn-bg-hover-color);
  --shop-form-btn-submit-border-hover-color: var(--btn-border-hover-color);

  /* fonts */
  --font-sans: 'FS Elliot Pro';

  /* nav */
  --header-item-hover-color: 254, 80, 0, 0.3;
  .nav-bottom-link-area {
    --header-base-color: var(--theme-colors-secondary);
    --nav-bottom-area-base-color: var(--theme-colors-secondary);
  }
}

/* EFFECTS */
.theme-py .bg-img-overlay {
  @apply bg-repeat bg-auto;
  background-image: url('/modules/assets/img/brand/PY/backgrounds/linen.png');
}

.theme-py h1,
.theme-py h2,
.theme-py h3,
.theme-py h4,
.theme-py h5,
.theme-py h6 {
  font-variant-ligatures: common-ligatures;
}

.theme-py {
  /* Design System font variables */
  --ds-headline-font-family: 'Domaine Extra Bold';

  --color-canopy-orange: #fe5000;
  --color-canopy-gray: #6e6e6e;
  --color-canopy-light-gray: #e6e6e6;

  .btn--base {
    @apply rounded-none font-normal -tracking-tight;
  }
  .surface-base,
  .surface-alternate {
    --ds-color-border: var(--color-neutral-400);
    --ds-color-interactive: var(--color-neutral-950);
    --ds-color-interactive-accent: var(--color-neutral-800);
    --ds-color-interactive-accent-contrast: var(--color-white);
    --ds-button-outline-border: var(--color-canopy-orange);
    --ds-button-accent-outline-foreground: var(--color-neutral-950);
    --ds-button-accent-outline-border: var(--color-canopy-gray);
    --ds-button-accent-flat-foreground: var(--color-neutral-950);
  }

  .surface-alternate {
    --ds-color-background: var(--color-canopy-light-gray);
  }

  .surface-inverse {
    --ds-color-border: var(--color-black);
    --ds-color-background: var(--color-neutral-950);
    --ds-color-interactive-hover: var(--color-canopy-orange);
  }
}
