/* Brand- Tempo */
@font-face {
  font-family: StyreneA Reg;
  font-display: swap;
  src: url('/modules/assets/fonts/styrene-a/styrenea-regular.woff2') format('woff2');
}

.theme-po {
  --color-primary: 40 40 40;
  --color-primary-alt: 40 40 40;
  --color-secondary: 40 40 40;
  --color-secondary-alt: 40 40 40;
  --color-brand: 40 40 40;
  --color-brand-alt: 40 40 40;
  --font-headline: 'StyreneA Reg';
  --color-text-heading: var(--color-primary);
  --heading-spacing-preset: normal;

  /* property-ui theme */
  --color-po-accent: 40 40 40;

  /* Image Headliner styles */
  --image-headliner-link-active-color: var(--color-text-inverse);
  --image-headliner-control-color-default: var(--color-text-inverse);
}

.theme-po h1,
.theme-po h2,
.theme-po h3,
.theme-po h4,
.theme-po h5,
.theme-po h6 {
  font-variant-ligatures: common-ligatures;
}

.theme-po {
  /* Design System font variables */
  --ds-headline-font-family: 'StyreneA Reg';

  --color-tempo-periwinkle: #d1d8ea;
  --color-tempo-malbec: #7f3f37;
  --color-tempo-taupe: #dcd7cf;

  .surface-base,
  .surface-alternate {
    --ds-color-interactive: var(--color-tempo-malbec);
    --ds-color-interactive-hover: var(--color-black);
  }

  .surface-alternate {
    --ds-color-background: var(--color-tempo-periwinkle);
  }

  .surface-inverse {
    --ds-color-background: var(--color-tempo-malbec);
    --ds-color-interactive: var(--color-tempo-taupe);
    --ds-color-interactive-hover: var(--color-tempo-periwinkle);
  }
}
