/* Brand- Hilton Garden Inn */
@font-face {
  font-family: Usual Light;
  font-display: swap;
  src: url('/modules/assets/fonts/usual/light.woff2') format('woff2');
}

@font-face {
  font-family: Bree Serif;
  font-display: swap;
  /* font-weight: 700; */
  src: url('/modules/assets/fonts/bree-serif-th/regular.woff2') format('woff2');
}

.theme-gi {
  --color-primary: 30 125 180;
  --color-primary-alt: 18 82 135;
  --color-secondary: 230 60 47;
  --color-secondary-alt: 230 60 47;
  --color-tertiary: 18 82 135;
  --color-tertiary-alt: 11 60 110;
  --color-brand: 84 86 90;
  --color-brand-alt: 84 86 90;
  --font-headline: 'Usual Light';
  --font-serif: 'Bree Serif';
  --color-bg-light: 229 247 252;
  --color-text-heading: var(--color-primary);

  /* property-ui theme */
  --color-gi-accent: 30 125 180;
  --color-gi-icon-accent: 84 86 90;

  --heading-spacing-preset: normal;

  /* Dynamic Grid WOM styles */
  --dynamic-grid-item-wom-link-anchor-color-light: var(--color-tertiary);
  --dynamic-grid-item-wom-link-anchor-hover-color-light: var(--color-tertiary-alt);
  --dynamic-grid-item-wom-header-color-light: var(--color-tertiary);
}

.theme-gi h1,
.theme-gi h2,
.theme-gi h3,
.theme-gi h4,
.theme-gi h5,
.theme-gi h6 {
  font-variant-ligatures: common-ligatures;
}

.theme-gi {
  /* Design System font variables */
  --ds-body-font-family: 'Bree Serif';
  --ds-headline-font-family: 'Usual Light';

  --color-hgi-light-blue: #7dd8f0;
  --color-hgi-bright-red: #e63c2f;
  --color-hgi-light-gray: #d2d0cd;
  --color-hgi-dark-blue-200: #bbe2ed;
  --color-hgi-dark-blue: #1e7db4;
  --color-hgi-dark-blue-700: #4690b3;
  --color-hgi-dark-red: #b21e28;
  --color-hgi-medium-gray: #99999a;
  --color-hgi-dark-gray: #54565a;

  .surface-base,
  .surface-alternate {
    --ds-color-interactive: var(--color-hgi-dark-red);
    --ds-color-interactive-hover: var(--color-black);
  }

  .surface-alternate {
    --ds-color-background: var(--color-hgi-dark-blue-200);
    --ds-color-interactive: var(--color-hgi-dark-blue-700);
  }

  .surface-inverse {
    --ds-color-background: var(--color-hgi-dark-blue);
    --ds-color-text-muted: var(--color-neutral-200);
  }
}
