/* Brand- DoubleTree */
@font-face {
  font-family: Stag Bold;
  font-display: swap;
  src: url('/modules/assets/fonts/stag/Stag-Bold.woff2') format('woff2');
}

.theme-dt {
  --color-primary: 207 72 2;
  --color-primary-alt: 207 72 2;
  --color-secondary: 207 72 2;
  --color-secondary-alt: 207 72 2;
  --color-brand: 9 25 78;
  --color-brand-alt: 9 25 78;
  --font-headline: 'Stag Bold';
  --color-text-heading: var(--color-brand);
  --heading-spacing-preset: normal;

  /* property-ui theme */
  --color-dt-accent: 9 25 78;
  --color-dt-border-accent: 207 72 2;

  --image-headliner-link-active-color: var(--color-brand);

  /* Vertical Tabbed styles */
  --vertical-tabbed-button-inactive-hover-text-color: var(--color-brand);
  --vertical-tabbed-button-inactive-hover-text-color-light: var(--color-brand);

  /* Patchwork Grid styles*/
  --patchwork-grid-headline-text-color-light: var(--color-brand);
  --patchwork-grid-row-headline-text-color-light: var(--color-brand);
}

.theme-dt h1,
.theme-dt h2,
.theme-dt h3,
.theme-dt h4,
.theme-dt h5,
.theme-dt h6 {
  font-variant-ligatures: common-ligatures;
}

.theme-dt {
  /* Design System font variables */
  --ds-body-font-family: 'Stag Bold';
  --ds-headline-font-family: 'Stag Bold';

  --color-doubletree-blue: #09194e;
  --color-doubletree-green: #a3cd39;
  --color-doubletree-orange: #cf4800;
  --color-doubletree-yellow: #ffcc00;

  --ds-color-border: var(--color-neutral-200);
  --ds-color-text: var(--color-doubletree-blue);
  --ds-color-headline: var(--color-doubletree-blue);
  --ds-color-interactive: var(--color-doubletree-blue);
  --ds-color-interactive-hover: var(--color-black);

  .surface-alternate {
    --ds-color-background: var(--color-doubletree-green);
  }

  .surface-inverse {
    --ds-color-background: var(--color-doubletree-blue);
    --ds-color-border: var(--color-black);
    --ds-color-headline: var(--color-doubletree-green);
    --ds-color-interactive-hover: var(--color-doubletree-green);
  }
}
