*,
:after,
:before {
  --font-body: var(--font-sans);
}

.bg-img-overlay {
  @apply bg-no-repeat bg-cover absolute inset-0 size-full z-0;
}

.horizontal-flip {
  @apply transform rotate-180;
}
