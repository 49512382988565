/* Brand- Embassy */
@font-face {
  font-family: Schibsted Grotesk;
  font-display: swap;
  font-weight: 400;
  src: url('/modules/assets/fonts/schibsted-grotesk/SchibstedGrotesk-Regular.ttf')
    format('truetype');
}

@font-face {
  font-family: Schibsted Grotesk;
  font-display: swap;
  font-weight: 700;
  src: url('/modules/assets/fonts/schibsted-grotesk/SchibstedGrotesk-Bold.ttf') format('truetype');
}

@font-face {
  font-family: Quiche Sans;
  font-display: swap;
  font-weight: 400;
  src: url('/modules/assets/fonts/quiche-sans/regular.woff2') format('woff2');
}

.theme-es {
  --color-primary: 13 83 77;
  --color-primary-alt: 10 64 59;
  --color-secondary: 157 52 90;
  --color-tertiary: 46 136 141;
  --color-quarternary: 224 121 7;
  --color-quarternary-alt: 175 202 173;
  --color-brand: 157 52 90;
  --color-bg-light: 211 227 210;
  --color-bg-dark: 13 83 77;
  --font-headline: 'Quiche Sans';
  --font-sans: 'Schibsted Grotesk';
  --font-body: 'Schibsted Grotesk';
  --color-text-heading: var(--color-primary);
  --heading-spacing-preset: 0em;
  --font-headline-weight: 400;

  /* property-ui theme */
  --color-es-accent: 13 83 77;
  --color-es-border-accent: 157 52 90;
  --color-es-icon-accent: 157 52 90;

  /* Header Navigation styles */
  --header-item-hover-color: var(--color-bg-alt);
  --header-item-hover-border-color: var(--color-secondary);

  /* button styles */
  --btn-text-color: var(--color-text-inverse);
  --btn-bg-color: var(--color-primary);
  --btn-bg-hover-color: var(--color-secondary);
  --btn-border-color: var(--color-primary);
  --btn-border-hover-color: var(--color-secondary);

  --btn-bg-color-dark: var(--color-text-inverse);
  --btn-bg-hover-color-dark: var(--color-primary);
  --btn-border-color-dark: var(--color-text-inverse);
  --btn-border-hover-color-dark: var(--color-text-inverse);
  --btn-text-color-dark: var(--color-primary);
  --btn-text-hover-color-dark: var(--color-text-inverse);

  --btn-bg-color-dark-solid: var(--color-text-inverse);
  --btn-bg-hover-color-dark-solid: var(--color-primary);
  --btn-border-color-dark-solid: var(--color-text-inverse);
  --btn-border-hover-color-dark-solid: var(--color-text-inverse);
  --btn-text-color-dark-solid: var(--color-primary);
  --btn-text-hover-color-dark-solid: var(--color-text-inverse);
  --btn-text-hover-color-dark-outline: var(--color-primary);

  --btn-border-color-outline: var(--color-primary);
  --btn-text-color-outline: var(--color-primary);
  --btn-bg-hover-color-outline: var(--color-primary);
  --btn-text-hover-color-outline: var(--color-text-inverse);
  --btn-border-hover-color-outline: var(--color-primary);

  /* Shop Form styles */
  --shop-form-btn-text-color: var(--color-primary);
  --shop-form-btn-border-color: var(--color-primary);
  --shop-form-btn-text-hover-color: var(--color-text-inverse);
  --shop-form-btn-bg-hover-color: var(--color-primary);
  --shop-form-btn-border-hover-color: var(--color-primary);
  --shop-form-btn-submit-bg-color: var(--color-secondary);
  --shop-form-btn-submit-border-color: var(--color-secondary);
  --shop-form-btn-submit-bg-hover-color: var(--color-primary);
  --shop-form-btn-submit-border-hover-color: var(--color-primary);

  /* Image Headliner styles */
  --image-headliner-font-family: var(--font-body);
  --image-headliner-font-weight: 400;
  --image-headliner-bg-color: var(--color-bg-alt);
  --image-headliner-text-color: var(--color-text);
  --image-headliner-link-text-color: var(--color-primary);

  /* Editorial Snippet styles */
  --editorial-snippet-short-description-text-color: var(--color-primary);
  --editorial-snippet-short-description-font-family: var(--font-headline);

  /* caption */
  --caption-main-font-weight: 400;

  /* Horizontal Tabbed styles */
  --horizontal-tabbed-list-scrollable-border-b-color: var(--color-text);
  --horizontal-tabbed-list-scrollable-border-b-color-light: var(--color-text);
  --horizontal-tabbed-list-button-active-border-b-color: var(--color-secondary);
  --horizontal-tabbed-list-button-active-border-b-color-light: var(--color-secondary);
  --horizontal-tabbed-list-button-active-border-b-color-dark: var(--color-quarternary);

  /* Vertical Tabbed styles */
  --vertical-tabbed-button-inactive-bg: var(--color-bg);
  --vertical-tabbed-button-inactive-border-color: var(--color-text);
  --vertical-tabbed-button-inactive-bg-light: var(--color-bg-light);
  --vertical-tabbed-button-inactive-border-color-light: var(--color-text);
  --vertical-tabbed-button-inactive-border-color-dark: var(--color-bg);
  --vertical-tabbed-button-inactive-text-color-dark: var(--color-text-inverse);
  --vertical-tabbed-button-inactive-bg-dark: var(--color-bg-dark);
  --vertical-tabbed-button-inactive-hover-text-color-dark: var(--color-bg-light);
  --vertical-tabbed-button-active-bg: var(--color-bg-alt);
  --vertical-tabbed-button-active-border-color: var(--color-text);
  --vertical-tabbed-button-active-before-bg: var(--color-secondary);
  --vertical-tabbed-button-active-bg-light: var(--color-quarternary-alt);
  --vertical-tabbed-button-active-border-color-light: var(--color-text);
  --vertical-tabbed-button-active-before-bg-light: var(--color-secondary);
  --vertical-tabbed-button-active-bg-dark: var(--color-primary-alt);
  --vertical-tabbed-button-active-border-color-dark: var(--color-bg);
  --vertical-tabbed-button-active-before-bg-dark: var(--color-quarternary);
  --vertical-tabbed-panel-border-color: var(--color-text);
  --vertical-tabbed-panel-border-color-light: var(--color-text);
  --vertical-tabbed-panel-border-color-dark: var(--color-bg);
  /* dynamic grid */
  --dynamic-grid-item-header-font-family: var(--font-headline);
  --dynamic-grid-item-header-font-weight: var(--font-headline-weight);
  --dynamic-grid-item-wom-header-font-weight: var(--font-headline-weight);
  --dynamic-grid-item-wom-header-color: var(--color-primary);
  /* Slider */
  --slider-gradient-on-color-dark: var(--color-primary);
  --slider-thumb-color-dark: var(--color-primary);
  /* card carousel */
  --card-carousel-list-item-border-color: var(--color-text);
  --card-carousel-item-border-color: var(--color-text);
  --card-carousel-list-item-border-color-light: var(--color-text);
}
.theme-es h1,
.theme-es h2,
.theme-es h3,
.theme-es h4,
.theme-es h5,
.theme-es h6 {
  font-variant-ligatures: common-ligatures;
}

.theme-es {
  /* Design System font variables */
  --ds-body-font-family: 'Schibsted Grotesk';
  --ds-headline-font-family: 'Quiche Sans';
  --ds-headline-font-weight: 400;

  --color-embassy-forest-green: #0d534d;
  --color-embassy-light-teal: #d3e3d2;
  --color-embassy-bold-teal: #2e888d;
  --color-embassy-berry: #9d345a;
  --color-embassy-tangerine: #e07907;

  .surface-base,
  .surface-alternate {
    --ds-color-headline: var(--color-embassy-forest-green);
    --ds-color-interactive: var(--color-embassy-forest-green);
    --ds-color-interactive-hover: var(--color-embassy-berry);
    --ds-color-interactive-accent: var(--color-embassy-berry);
    --ds-color-interactive-accent-hover: var(--color-embassy-forest-green);
  }

  .surface-alternate {
    --ds-color-background: var(--color-embassy-light-teal);
  }

  .surface-inverse {
    --ds-color-border: var(--color-neutral-800);
    --ds-color-background: var(--color-embassy-forest-green);
    --ds-color-interactive-hover: var(--color-embassy-light-teal);
    --ds-color-interactive-accent: var(--color-embassy-berry);
    --ds-color-interactive-accent-contrast: var(--color-white);
    --ds-color-interactive-accent-hover: var(--color-white);
    --ds-color-interactive-accent-hover-contrast: var(--color-embassy-berry);
    --ds-button-accent-outline-foreground: var(--color-white);
    --ds-button-accent-flat-foreground: var(--color-white);
    --ds-button-accent-flat-foreground-hover: var(--color-embassy-light-teal);
  }

  .heading--base {
    @apply font-light tracking-normal;
  }
}
