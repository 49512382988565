/* Brand- Graduate Hotels */

/* ============================= TYPOGRAPHY ============================= */

@font-face {
  font-family: Graduate;
  font-weight: 400;
  font-display: swap;
  src: url('/modules/assets/fonts/graduate-type/regular.woff2') format('woff2');
}

@font-face {
  font-family: Graduate;
  font-weight: 700;
  font-display: swap;
  src: url('/modules/assets/fonts/graduate-type/regular.woff2') format('woff2');
}

@font-face {
  font-family: Graduate;
  font-weight: 900;
  font-display: swap;
  src: url('/modules/assets/fonts/graduate-type/bold.woff2') format('woff2');
}

@font-face {
  font-family: GT America Mono;
  font-weight: 300;
  font-display: swap;
  src: url('/modules/assets/fonts/gt-america-mono/light.woff2') format('woff2');
}

@font-face {
  font-family: GT Walsheim;
  font-weight: 700;
  font-display: swap;
  src: url('/modules/assets/fonts/gt-walsheim/GT-Walsheim-Bold.woff') format('woff');
}

@font-face {
  font-family: GT Walsheim;
  font-weight: 400;
  font-display: swap;
  src: url('/modules/assets/fonts/gt-walsheim/GT-Walsheim-Regular.woff') format('woff');
}

/* ============================= THEMING ============================= */
.theme-gu {
  --color-primary: 19 26 54;
  --color-primary-alt: 74 87 104;
  --color-secondary: 143 163 190;
  --color-tertiary: 74 87 104;
  --color-quarternary: 247 245 242;
  --color-brand: 143 163 190;
  --color-bg-light: 143 163 190;
  --color-bg-dark: 19 26 54;
  --font-headline: 'Graduate';
  --font-headline-brand: 'Graduate';
  --font-sans: 'GT Walsheim';
  --font-mono: 'GT America Mono';

  /* property-ui theme */
  --color-gu-primary: 19 26 54;
  --color-gu-primary-contrast: 255 255 255;
  --color-gu-secondary: 74 87 104;
  --color-gu-secondary-contrast: 255 255 255;
  --color-gu-tertiary: 247 245 242;
  --color-gu-tertiary-contrast: 18 18 18;
  --color-gu-accent: 143 163 190;

  /* Heading presets */
  --color-text-heading: var(--color-primary);
  --heading-spacing-preset: 0em;
  --font-headline-weight: 400;

  --nav-main-link-font-weight: 400;
  --nav-main-link-font-family: var(--font-mono);
  --nav-item-text-font-size: 1rem;
  --nav-item-text-line-height: 1.5rem;

  --nav-item-dark-active-text-color: var(--color-primary);
  --nav-item-active-bg-dark-color: var(--color-quarternary);

  --user-icon-dark-active-fill-color: var(--color-primary);

  --nav-divider-bottom-padding: 0;
  --nav-popup-margin: 0;
  --nav-item-border-width: 0;

  --nav-bottom-area-dark-base-color: var(--color-tertiary);

  /* button styles */
  /* Note: Graduate default button styles are transparent with an underline. */
  --btn-base-border-radius: none;
  --btn-base-font-weight: 400;
  --btn-base-letter-spacing: 1px;
  --btn-border-style: none;
  --btn-font-family: var(--font-mono);
  --btn-padding: 8px 0px;
  --btn-text-decoration-line: none;
  --btn-text-decoration-thickness: 2px;
  --btn-text-transform: uppercase;
  --btn-text-underline-offset: 8px;
  --btn-room-card-font-weight: 700;

  --btn-bg-color: var(--transparent);
  --btn-bg-hover-color: --transparent;
  --btn-text-color: var(--color-primary);
  --btn-text-hover-color: var(--color-primary-alt);

  --btn-text-color-dark: var(--color-text-inverse);
  --btn-text-hover-color-dark: var(--color-secondary);

  --btn-bg-color-dark-solid: var(--color-text-inverse);
  --btn-bg-hover-color-dark-solid: var(--color-secondary);
  --btn-border-color-dark-solid: var(--color-primary);
  --btn-border-hover-color-dark-solid: var(--color-secondary);
  --btn-text-color-dark-solid: var(--color-primary);
  --btn-text-hover-color-dark-solid: var(--color-primary);

  --btn-bg-color-solid: var(--color-primary);
  --btn-bg-hover-color-solid: var(--color-tertiary);
  --btn-border-color-solid: var(--color-primary);
  --btn-border-hover-color-solid: var(--color-tertiary);
  --btn-text-color-solid: var(--color-text-inverse);
  --btn-text-hover-color-solid: var(--color-text-inverse);

  --btn-bg-hover-color-dark-outline: var(--color-secondary);
  --btn-border-hover-color-dark-outline: var(--color-secondary);

  --btn-bg-hover-color-outline: var(--color-tertiary);
  --btn-border-hover-color-outline: var(--color-tertiary);
  --btn-text-hover-color-outline: var(--color-text-inverse);

  --shop-form-btn-letter-spacing: 0.05em;
  --shop-form-btn-submit-letter-spacing: var(--shop-form-btn-letter-spacing);
  --shop-form-btn-text-color: var(--color-primary);
  --shop-form-btn-bg-color: var(--color-bg);
  --shop-form-btn-bg-hover-color: var(--color-secondary);
  --shop-form-btn-border-color: var(--color-primary);
  --shop-form-btn-text-hover-color: var(--color-primary);

  /* Image Headliner styles */
  --image-headliner-bg-color: var(--color-quarternary);
  --image-headliner-text-color: var(--color-text);
  --image-headliner-font-weight: 400;
  --image-headliner-font-family: var(--font-sans);
  --image-headliner-link-text-color: var(--color-primary);

  /* Editorial Snippet styles */
  --editorial-snippet-short-description-text-color: var(--color-primary);
  --editorial-snippet-short-description-font-family: var(--font-headline);

  /* alerts */
  --alert-bg-color: var(--theme-colors-primary);
  --alert-text-color: var(--theme-colors-primary-contrast);
  --alert-icon-color: var(--theme-colors-primary-contrast);

  --caption-main-font-weight: 400;
  --caption-main-font-family: var(--font-mono);

  /* Horizontal Tabbed styles */
  --horizontal-tabbed-list-scrollable-border-b-color: var(--color-primary);
  --horizontal-tabbed-list-scrollable-border-b-color-light: var(--color-primary);
  --horizontal-tabbed-list-scrollable-border-b-color-dark: var(--color-secondary);
  --horizontal-tabbed-arrow-wrapper-gradient-bg: var(--color-quarternary);
  --horizontal-tabbed-list-button-active-border-b-color: var(--color-secondary);
  --horizontal-tabbed-list-button-active-border-b-color-dark: var(--color-quarternary);

  /* Vertical Tabbed styles */
  --vertical-tabbed-accordion-border-color: var(--color-primary);
  --vertical-tabbed-accordion-border-color-light: var(--color-primary);
  --vertical-tabbed-accordion-expanded-bg-color: var(--color-bg);
  --vertical-tabbed-accordion-expanded-bg-color-light: var(--color-primary-alt);
  --vertical-tabbed-accordion-expanded-text-color-light: var(--color-text-inverse);
  --vertical-tabbed-accordion-expanded-border-color-light: var(--color-text-inverse);
  --vertical-tabbed-accordion-expanded-bg-color-dark: var(--color-primary-alt);
  --vertical-tabbed-accordion-icon-indicator-expanded-fill-color-light: var(--color-bg);
  --vertical-tabbed-button-inactive-bg: var(--color-quarternary);
  --vertical-tabbed-button-inactive-border-color: var(--color-primary);
  --vertical-tabbed-button-inactive-text-color: var(--color-primary);
  --vertical-tabbed-button-inactive-bg-light: var(--color-bg-light);
  --vertical-tabbed-button-inactive-border-color-light: var(--color-primary);
  --vertical-tabbed-button-inactive-text-color-light: var(--color-primary);
  --vertical-tabbed-button-inactive-bg-dark: var(--color-bg-dark);
  --vertical-tabbed-button-inactive-border-color-dark: var(--color-quarternary);
  --vertical-tabbed-button-inactive-text-color-dark: var(--color-text-inverse);
  --vertical-tabbed-button-inactive-hover-text-color-dark: var(--color-secondary);
  --vertical-tabbed-button-active-bg-light: var(--color-primary-alt);
  --vertical-tabbed-button-active-border-color: var(--color-primary);
  --vertical-tabbed-button-active-border-color-light: var(--color-primary);
  --vertical-tabbed-button-active-text-color-light: var(--color-text-inverse);
  --vertical-tabbed-button-active-bg-dark: var(--color-primary-alt);
  --vertical-tabbed-button-active-border-color-dark: var(--color-quarternary);
  --vertical-tabbed-panel-border-color: var(--color-primary);
  --vertical-tabbed-panel-border-color-light: var(--color-primary);
  --vertical-tabbed-panel-border-color-dark: var(--color-quarternary);
  /* dynamic grid */
  --dynamic-grid-header-text-align: center;
  --dynamic-grid-description-text-align: center;
  --dynamic-grid-wom-header-text-align: center;
  --dynamic-grid-wom-subheader-text-align: center;
  --dynamic-grid-wom-description-text-align: center;
  --dynamic-grid-item-header-font-family: var(--font-headline);
  --dynamic-grid-item-header-font-weight: 400;
  --dynamic-grid-item-wom-header-font-size: 1.5rem;
  --dynamic-grid-item-wom-header-line-height: 2rem;
  --dynamic-grid-item-wom-link-font-size: 1rem;
  --dynamic-grid-item-wom-link-line-height: 1.5rem;
  --dynamic-grid-item-wom-link-anchor-font-family: var(--font-mono);
  --dynamic-grid-item-wom-link-anchor-text-transform: uppercase;
  --dynamic-grid-item-wom-link-anchor-letter-spacing: 0.05em;
  --dynamic-grid-item-wom-link-anchor-text-decoration: none;
  --dynamic-grid-item-wom-link-label-display: block;
  --dynamic-grid-item-wom-link-label-bg: var(--color-primary);
  --dynamic-grid-item-wom-link-label-bg-dark: var(--color-bg);
  /* Slider */
  --slider-gradient-on-color-dark: var(--color-primary);
  --slider-thumb-color-dark: var(--color-primary);
  /* card carousel */
  --card-carousel-background-color: var(--color-quarternary);
  --card-carousel-list-item-background-color: var(--color-quarternary);
  --card-carousel-list-item-border-color: var(--color-primary);
  --card-carousel-item-background-color: var(--color-quarternary);
  --card-carousel-list-item-border-color-light: var(--color-primary);
}

@screen lg {
  .theme-gu {
    /* Patchwork Grid Row */
    --patchwork-grid-row-headline-font-size: 2.25rem;
    --patchwork-grid-row-headline-line-height: 2.5rem;

    --dynamic-grid-item-wom-header-font-size: 1.875rem;
    --dynamic-grid-item-wom-header-line-height: 2.25rem;
    --dynamic-grid-item-wom-link-font-size: 1.125rem;
    --dynamic-grid-item-wom-link-line-height: 1.75rem;
  }
}

.theme-gu .btn-brand:after {
  content: '';
  background-color: rgb(var(--btn-text-color));
  position: absolute;
  display: block;
  height: var(--btn-underline-height, 1px);
  width: var(--btn-underline-width, 100%);
  transform-origin: bottom left;
  transform: scaleX(1);
  transition-duration: var(--btn-underline-transition-duration, 250ms);
  transition-timing-function: ease-out;
}

.theme-gu .btn-brand:hover:after {
  transform: scaleX(0);
  transition-timing-function: var(--btn-transition-timing-function ease-out);
}

.theme-gu .btn-brand-dark:hover:after {
  background-color: rgb(var(--btn-text-hover-color-dark));
}

.theme-gu .btn-brand-dark:after {
  background-color: rgb(var(--btn-text-color-dark));
}

.theme-gu .btn-brand-solid:after,
.theme-gu .btn-brand-outline:after,
.theme-gu .btn-brand-light-outline:after,
.theme-gu .btn-brand-light-solid:after,
.theme-gu .btn-brand-dark-outline:after,
.theme-gu .btn-brand-dark-solid:after {
  display: none;
}

.theme-gu {
  /* Design System font variables */
  --ds-body-font-family: 'GT Walsheim';
  --ds-headline-font-family: 'Graduate';

  --color-graduate-navy-blue: #131a36;
  --color-graduate-slate-blue: #4a5768;
  --color-graduate-light-blue: #8fa3be;
  --color-graduate-blue: #0033a1;
  --color-graduate-green: #375542;
  --color-graduate-red: #c10230;
  --color-graduate-gold: #ffcd00;
  --color-graduate-gray: #f2f2f2;
  --color-graduate-cream: #f8f4e5;

  .surface-base,
  .surface-alternate {
    --ds-color-text: var(--color-graduate-navy-blue);
    --ds-color-border: var(--color-neutral-400);
    --ds-color-background: var(--color-graduate-cream);
    --ds-color-interactive: var(--color-graduate-navy-blue);
    --ds-color-interactive-hover: var(--color-black);
  }

  .surface-alternate {
    --ds-color-border: var(--color-graduate-slate-blue);
    --ds-color-background: var(--color-graduate-light-blue);
  }

  .surface-inverse {
    --ds-color-border: var(--color-graduate-slate-blue);
    --ds-color-background: var(--color-graduate-navy-blue);
    --ds-color-interactive-hover: var(--color-neutral-50);
  }

  .btn--base {
    @apply rounded-none uppercase tracking-widest font-normal;
  }

  .btn--brand.btn--auto:not(:disabled) {
    --btn-fg-color: var(--ds-button-flat-foreground, var(--btn-color));
    &:hover:not(:disabled),
    &:focus:not(:disabled),
    &:active:not(:disabled),
    &:focus-within:not(:disabled) {
      --btn-fg-color: var(--ds-button-flat-foreground-hover, var(--btn-color-hover));
    }
  }

  .btn--accent.btn--auto:not(:disabled) {
    --btn-fg-color: var(--ds-button-accent-flat-foreground, var(--btn-color));
    &:hover:not(:disabled),
    &:focus:not(:disabled),
    &:active:not(:disabled),
    &:focus-within:not(:disabled) {
      --btn-fg-color: var(--ds-button-accent-flat-foreground-hover, var(--btn-color-hover));
    }
  }

  .btn--auto {
    @apply bg-transparent border-transparent relative px-0 after:absolute after:block after:h-px after:w-full after:bg-current after:origin-bottom-left after:scale-x-100 motion-safe:after:transition after:duration-300 after:ease-out;
    &:not(:disabled) {
      @apply after:hover:scale-x-0 hover:after:ease-out;
    }
  }
}
