/* Brand- Waldorf Astoria */
@font-face {
  font-family: Waldorf Astoria Sans;
  font-display: swap;
  src: url('/modules/assets/fonts/waldorf-astoria-sans/WaldorfAstoriaSans-Regular.woff2')
    format('woff2');
}

@font-face {
  font-family: Waldorf Astoria Sans;
  font-display: swap;
  font-weight: 300;
  src: url('/modules/assets/fonts/waldorf-astoria-sans/WaldorfAstoriaSans-Light.woff2')
    format('woff2');
}

@font-face {
  font-family: Waldorf Astoria Sans;
  font-display: swap;
  font-weight: 700;
  src: url('/modules/assets/fonts/waldorf-astoria-sans/WaldorfAstoriaSans-Bold.woff2')
    format('woff2');
}

@font-face {
  font-family: Waldorf Astoria Serif;
  font-weight: 200;
  font-display: swap;
  src: url('/modules/assets/fonts/waldorf-astoria-serif/WaldorfAstoriaSerif-Regular.woff2')
    format('woff2');
}

@font-face {
  font-family: Waldorf Astoria Serif;
  font-weight: 400;
  font-display: swap;
  src: url('/modules/assets/fonts/waldorf-astoria-serif/WaldorfAstoriaSerif-Regular.woff2')
    format('woff2');
}

@font-face {
  font-family: Waldorf Astoria Serif;
  font-weight: 600;
  font-display: swap;
  src: url('/modules/assets/fonts/waldorf-astoria-serif/WaldorfAstoriaSerif-Regular.woff2')
    format('woff2');
}

@font-face {
  font-family: Waldorf Astoria Serif;
  font-weight: 700;
  src: url('/modules/assets/fonts/waldorf-astoria-serif/WaldorfAstoriaSerif-Bold.woff2')
    format('woff2');
}

@font-face {
  font-family: Waldorf Astoria Serif;
  font-weight: 800;
  font-display: swap;
  src: url('/modules/assets/fonts/waldorf-astoria-serif/WaldorfAstoriaSerif-Bold.woff2')
    format('woff2');
}

.theme-wa {
  --color-primary: 3 33 101;
  --color-primary-alt: 18 18 18;
  --color-secondary: 0 0 0;
  --color-secondary-alt: 0 0 0;
  --color-brand: 0 0 0;
  --color-brand-alt: 0 0 0;
  --font-body: var(--font-serif);
  --font-sans: 'Waldorf Astoria Sans';
  --font-serif: 'Waldorf Astoria Serif';
  --font-headline: 'Waldorf Astoria Sans';
  --color-bg: 247 246 244;
  --color-bg-light: 247 246 244;
  --color-bg-dark: 3 33 101;
  --font-headline-weight: 300;

  /* property-ui theme */
  --color-wa-accent: 3 33 101;
  --color-wa-heading-accent: 18 18 18;
  --color-wa-icon-accent: 0 0 0;

  /* Heading presets */
  --color-text-heading: var(--color-text);
  --heading-transform-preset: uppercase;
  --heading-spacing-preset: 0.025em;

  --nav-item-font-weight: 400;
  --nav-main-link-font-weight: 400;

  /* button styles */
  /* Note: Waldorf default button styles are transparent with an underline. */
  --btn-base-border-radius: none;
  --btn-base-font-weight: 400;
  --btn-base-letter-spacing: 1px;
  --btn-border-style: none;
  --btn-padding: 0px 0px;
  --btn-text-decoration-line: underline;
  --btn-text-decoration-thickness: 2px;
  --btn-text-transform: uppercase;
  --btn-text-underline-offset: 8px;

  --btn-bg-color: var(--transparent);
  --btn-bg-hover-color: var(--transparent);
  --btn-text-color: var(--color-primary);
  --btn-text-hover-color: var(--color-primary);

  --btn-bg-color-solid: var(--color-primary);
  --btn-text-color-solid: var(--color-text-inverse);
  --btn-bg-hover-color-solid: var(--transparent);
  --btn-text-hover-color-solid: var(--color-primary);

  --btn-bg-hover-color-outline: var(--color-primary);
  --btn-text-hover-color-outline: var(--color-text-inverse);

  --btn-text-color-dark: var(--color-text-inverse);
  --btn-text-hover-color-dark: var(--color-text-inverse);

  --btn-bg-color-dark-solid: var(--color-bg);
  --btn-bg-hover-color-dark-solid: var(--color-bg);

  /* Image Headliner styles */
  --image-headliner-bg-color: var(--color-primary);
  --image-headliner-font-weight: 300;
  --image-headliner-font-family: var(--font-sans);
  --image-headliner-font-size: 1.125rem;
  --image-headliner-text-line-height: 1.75rem;
  --image-headliner-lg-font-size: 1.25rem;
  --image-headliner-lg-text-line-height: 1.75rem;
  --image-headliner-text-decoration-thickness: 1px;
  --image-headliner-link-active-color: var(--color-bg-light);
  --image-headliner-control-color-default: var(--color-text-inverse);

  /* Editorial Snippet styles */
  --editorial-snippet-short-description-text-color: var(--color-text);
  --editorial-snippet-short-description-text-color-light: var(--color-text);

  /* Patchwork Grid */
  --patchwork-grid-headline-text-alignment: 'start';
  --patchwork-grid-description-font-family: var(--font-serif);
  --patchwork-grid-description-text-alignment: 'start';
  /* Patchwork Grid Row */
  --patchwork-grid-row-description-font-family: var(--font-serif);
  --caption-main-font-family: var(--font-serif);
  --patchwork-grid-headline-text-color-light: var(--color-text);
  --patchwork-grid-row-headline-text-color-light: var(--color-text);

  /* Brand Tabbed styles (Apply to Both Horizontal and Vertical Tabbed) */
  --tabbed-bg-light: var(--color-transparent);

  /* Vertical Tabbed styles */
  --vertical-tabbed-button-inactive-hover-text-color: var(--color-primary);
  --vertical-tabbed-button-inactive-hover-text-color-light: var(--color-primary);
  /* dynamic grid */
  --dynamic-grid-item-header-font-weight: 400;
  --dynamic-grid-item-header-letter-spacing: 1px;
  --dynamic-grid-item-header-decoration-line: underline;
  --dynamic-grid-item-header-underline-offset: 8px;
  --dynamic-grid-item-header-decoration-thickness: 2px;
  --dynamic-grid-item-wom-header-font-weight: 400;
  --dynamic-grid-item-wom-header-text-transform: uppercase;
  --dynamic-grid-item-wom-link-anchor-font-weight: 400;
  --dynamic-grid-item-wom-link-anchor-text-transform: uppercase;
  --dynamic-grid-item-wom-link-anchor-letter-spacing: 1px;
  --dynamic-grid-item-wom-link-anchor-text-decoration: underline;
  --dynamic-grid-item-wom-link-anchor-underline-offset: 8px;
  --dynamic-grid-item-wom-link-anchor-decoration-thickness: 2px;
  --dynamic-grid-item-wom-link-anchor-background-color: transparent;
  --dynamic-grid-item-wom-link-anchor-border: none;
  /* card carousel */
  --card-carousel-background-color-light: transparent;
  --card-carousel-main-headline-editorial-text-color: var(--color-brand);
  --card-carousel-main-headline-editorial-text-transform: uppercase;
  --card-carousel-main-headline-editorial-text-font-weight: 300;
  --card-carousel-main-headline-editorial-text-font-family: var(--font-sans);
  --card-carousel-main-headline-standalone-text-transform: uppercase;
  --card-carousel-list-item-background-color-light: var(--transparent);
  --card-carousel-list-item-border-color: var(--color-primary);
  --card-carousel-list-item-border-color-dark: var(--color-text-inverse);
}

@screen sm {
  .theme-wa {
    --dynamic-grid-item-header-padding-bottom: 2.5rem;
  }
}
@screen xl {
  .theme-wa {
    --dynamic-grid-item-header-padding-bottom: 1.5rem;
    --dynamic-grid-item-header-padding-top: 1.5rem;
    --dynamic-grid-item-header-font-size: 1.5rem;
    --dynamic-grid-item-header-line-height: 2rem;
  }
}

.theme-wa .btn-brand-solid,
.theme-wa .btn-brand-light-solid,
.theme-wa .btn-brand-dark-solid,
.theme-wa .btn-brand-outline,
.theme-wa .btn-brand-light-outline,
.theme-wa .btn-brand-dark-outline {
  @apply no-underline;
}

.theme-wa h1,
.theme-wa h2,
.theme-wa h3,
.theme-wa h4,
.theme-wa h5,
.theme-wa h6 {
  font-variant-ligatures: common-ligatures;
}

/* EFFECTS */
.theme-wa .bg-img-overlay {
  @apply bg-no-repeat;
  @apply bg-bottom;
  @apply bg-auto;
  @apply hidden lg:block;
  background-image: url('/modules/assets/img/brand/WA/backgrounds/WA_BlueBar_BackgroundAsset.svg');
}

.theme-wa {
  /* Design System font variables */
  --ds-body-font-family: 'Waldorf Astoria Sans';
  --ds-headline-font-family: 'Waldorf Astoria Sans';
  --ds-headline-font-weight: 300;

  --color-waldorf-peacock-blue: #032165;
  --color-waldorf-stone: #f7f6f4;
  --color-waldorf-charcoal: #3d3935;
  --color-waldorf-sable-gray: #75787b;
  --color-waldorf-mist: #829999;
  --color-waldorf-teal: #9abaad;

  .surface-base,
  .surface-alternate {
    --ds-color-interactive: var(--color-waldorf-peacock-blue);
    --ds-color-interactive-hover: var(--color-black);
    --ds-color-interactive-accent: var(--color-waldorf-mist);
    --ds-color-interactive-accent-hover: var(--color-waldorf-charcoal);
  }

  .surface-alternate {
    --ds-color-background: var(--color-waldorf-stone);
  }

  .surface-inverse {
    --ds-color-background: var(--color-waldorf-peacock-blue);
    --ds-color-interactive-hover: var(--color-waldorf-teal);
  }

  .heading--base {
    @apply uppercase font-light;
  }

  .btn--base {
    @apply rounded-none uppercase font-normal;
  }

  .btn--brand.btn--auto:not(:disabled) {
    --btn-fg-color: var(--ds-button-flat-foreground, var(--btn-color));
    &:hover:not(:disabled),
    &:focus:not(:disabled),
    &:active:not(:disabled),
    &:focus-within:not(:disabled) {
      --btn-fg-color: var(--ds-button-flat-foreground-hover, var(--btn-color-hover));
    }
  }

  .btn--accent.btn--auto:not(:disabled) {
    --btn-fg-color: var(--ds-button-accent-flat-foreground, var(--btn-color));
    &:hover:not(:disabled),
    &:focus:not(:disabled),
    &:active:not(:disabled),
    &:focus-within:not(:disabled) {
      --btn-fg-color: var(--ds-button-accent-flat-foreground-hover, var(--btn-color-hover));
    }
  }

  .btn--auto {
    @apply bg-transparent border-transparent underline decoration-2 underline-offset-8 tracking-wide px-0;
  }
}
