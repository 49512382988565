/* Brand- Signia */
@font-face {
  font-family: Alp-Con-Light;
  font-display: swap;
  src: url('/modules/assets/fonts/gt-alpina/gt-alpina-standard-light.woff') format('woff');
}

@font-face {
  font-family: Alp-Con-Light;
  font-weight: 700;
  font-display: swap;
  src: url('/modules/assets/fonts/proxima-nova/semibold.woff2') format('woff2');
}

/* THEMES */
.theme-sa {
  --color-primary: 53 74 114;
  --color-primary-alt: 53 74 114;
  --color-secondary: 184 101 43;
  --color-secondary-alt: 184 101 43;
  --color-brand: 53 74 114;
  --color-brand-alt: 53 74 114;
  --font-headline: 'Alp-Con-Light';
  --color-bg-light: 208 222 236;
  --color-bg-dark: 31 54 104;
  --color-text-heading: var(--color-primary);
  --heading-spacing-preset: normal;

  /* property-ui theme */
  --color-sa-accent: 53 74 114;

  /* Image Headliner styles */
  --image-headliner-control-color-default: var(--color-text-inverse);
}

.theme-sa h1,
.theme-sa h2,
.theme-sa h3,
.theme-sa h4,
.theme-sa h5,
.theme-sa h6 {
  font-variant-ligatures: common-ligatures;
}

/* EFFECTS */
.theme-sa .bg-img-overlay {
  background-image: url('/modules/assets/img/brand/SA/backgrounds/signia_rock_bg.png');
}

.theme-sa {
  /* Design System font variables */
  --ds-headline-font-family: 'Alp-Con-Light';

  --color-signia-blue: #1f3668;
  --color-signia-dark-blue: #0f224a;
  --color-signia-light-blue: #d0deec;
  --color-signia-burgundy: #4a1437;
  --color-signia-coral: #e66cb4;
  --color-signia-dark-green: #0a444a;
  --color-signia-teal: #36837f;

  .surface-base,
  .surface-alternate {
    --ds-color-text: var(--color-signia-blue);
    --ds-color-headline: var(--color-signia-blue);
    --ds-color-interactive: var(--color-signia-blue);
    --ds-color-interactive-hover: var(--color-signia-dark-blue);
  }

  .surface-alternate {
    --ds-color-border: var(--color-neutral-300);
    --ds-color-background: var(--color-signia-light-blue);
  }

  .surface-inverse {
    --ds-color-border: var(--color-signia-dark-blue);
    --ds-color-background: var(--color-signia-blue);
    --ds-color-interactive-hover: var(--color-signia-light-blue);
    --ds-color-text-muted: var(--color-neutral-300);
  }
}
