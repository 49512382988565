/* Brand- SLH */
@font-face {
  font-family: Avenir;
  font-weight: 200;
  font-display: swap;
  src: url('/modules/assets/fonts/avenir/light.woff2') format('woff2');
}

@font-face {
  font-family: Avenir;
  font-weight: 400;
  font-display: swap;
  src: url('/modules/assets/fonts/avenir/regular.woff2') format('woff2');
}

@font-face {
  font-family: Avenir;
  font-weight: 600;
  font-display: swap;
  src: url('/modules/assets/fonts/avenir/semibold.woff2') format('woff2');
}

@font-face {
  font-family: Avenir;
  font-weight: 700;
  font-display: swap;
  src: url('/modules/assets/fonts/avenir/bold.woff2') format('woff2');
}

@font-face {
  font-family: Avenir;
  font-weight: 800;
  font-display: swap;
  src: url('/modules/assets/fonts/avenir/bold.woff2') format('woff2');
}

@font-face {
  font-family: Clearface Serial;
  font-weight: 200;
  font-display: swap;
  src: url('/modules/assets/fonts/clearface-serial/extra-light.woff2') format('woff2');
}

@font-face {
  font-family: Clearface Serial;
  font-weight: 300;
  font-display: swap;
  src: url('/modules/assets/fonts/clearface-serial/light.woff2') format('woff2');
}

@font-face {
  font-family: Clearface Serial;
  font-weight: 400;
  font-display: swap;
  src: url('/modules/assets/fonts/clearface-serial/regular.woff2') format('woff2');
}

@font-face {
  font-family: Clearface Serial;
  font-weight: 600;
  font-display: swap;
  src: url('/modules/assets/fonts/clearface-serial/medium.woff2') format('woff2');
}

@font-face {
  font-family: Clearface Serial;
  font-weight: 700;
  font-display: swap;
  src: url('/modules/assets/fonts/clearface-serial/bold.woff2') format('woff2');
}

@font-face {
  font-family: Clearface Serial;
  font-weight: 800;
  font-display: swap;
  src: url('/modules/assets/fonts/clearface-serial/extra-bold.woff2') format('woff2');
}

/* THEMES */
.theme-lx {
  --color-primary: 40 40 40;
  --color-primary-alt: 102 102 102;
  --color-secondary: 247 240 234;
  --color-brand: 158 105 61;
  --color-tertiary: 244 204 44;
  --color-tertiary-alt: 224 188 44;
  --font-headline: 'Clearface Serial';
  --color-bg-light: 247 240 234;
  --color-text-heading: var(--color-primary);
  --heading-spacing-preset: normal;
  --font-sans: 'Avenir';
  --font-serif: 'Clearface Serial';
  --font-headline-weight: 400;

  /* property-ui theme */
  --color-lx-primary: 255 255 255;
  --color-lx-primary-contrast: 40 40 40;
  --color-lx-secondary: 247 240 234;
  --color-lx-secondary-contrast: 40 40 40;
  --color-lx-tertiary: 244 204 44;
  --color-lx-tertiary-contrast: 40 40 40;
  --color-lx-accent: 158 105 61;

  /* heading sizes */
  --font-headline-sm: 1rem;
  --font-headline-base: 1.125rem;
  --font-headline-lg: 1.25rem;
  --font-headline-xl: 1.5rem;
  --font-headline-2xl: 1.875rem;
  --font-headline-3xl: 2.25rem;
  --font-headline-4xl: 3rem;
  --font-headline-5xl: 3.75rem;
  --font-headline-6xl: 4.5rem;
  --font-headline-7xl: 6rem;
  --font-headline-8xl: 8rem;

  /* lineheight sizes */
  --headline-lineheight-sm: 1.5rem;
  --headline-lineheight-base: 1.75rem;
  --headline-lineheight-lg: 1.75rem;
  --headline-lineheight-xl: 2rem;
  --headline-lineheight-2xl: 2.25rem;
  --headline-lineheight-3xl: 2.5rem;
  --headline-lineheight-4xl: 1;

  /* header styles */
  --header-item-hover-color: var(--color-bg-light);
  --header-item-hover-border-color: var(--color-primary);
  --header-base-color: var(--color-bg);

  /* button styles */
  --btn-base-border-radius: none;
  --btn-base-font-weight: 600;

  --btn-bg-color: var(--transparent);
  --btn-bg-hover-color: var(--color-primary);
  --btn-text-color: var(--color-text);
  --btn-text-hover-color: var(--color-secondary);

  --btn-bg-color-solid: var(--color-tertiary);
  --btn-border-color-solid: var(--color-primary);
  --btn-bg-hover-color-solid: var(--color-tertiary-alt);
  --btn-text-color-solid: var(--color-primary);
  --btn-border-hover-color-solid: var(--color-primary);
  --btn-text-hover-color-solid: var(--color-primary);

  --btn-border-color-outline: var(--color-primary);
  --btn-text-color-outline: var(--color-primary);
  --btn-bg-hover-color-outline: var(--color-primary);
  --btn-border-hover-color-outline: var(--color-primary);
  --btn-text-hover-color-outline: var(--color-text-inverse);

  --btn-bg-color-dark-solid: var(--btn-bg-color-dark);
  --btn-bg-hover-color-dark-solid: var(--btn-bg-hover-color-dark);
  --btn-border-color-dark-solid: var(--btn-border-color-dark);
  --btn-border-hover-color-dark-solid: var(--btn-border-hover-color-dark);
  --btn-text-color-dark-solid: var(--btn-text-color-dark);
  --btn-text-hover-color-dark-solid: var(--btn-text-hover-color-dark);

  --btn-bg-color-dark: var(--color-tertiary);
  --btn-bg-hover-color-dark: var(--color-tertiary-alt);
  --btn-border-color-dark: var(--color-primary);
  --btn-border-hover-color-dark: var(--color-primary);
  --btn-text-color-dark: var(--color-primary);
  --btn-text-hover-color-dark: var(--color-primary);

  --shop-form-btn-text-color: var(--color-primary);
  --shop-form-btn-border-color: var(--color-primary);
  --shop-form-btn-text-hover-color: var(--color-secondary);
  --shop-form-btn-border-hover-color: var(--color-primary);
  --shop-form-btn-bg-hover-color: var(--color-primary);

  --shop-form-btn-submit-text-color: var(--color-text);
  --shop-form-btn-submit-border-color: var(--color-primary);
  --shop-form-btn-submit-bg-color: var(--color-tertiary);
  --shop-form-btn-submit-text-hover-color: var(--color-primary);
  --shop-form-btn-submit-border-hover-color: var(--color-primary);
  --shop-form-btn-submit-bg-hover-color: var(--color-tertiary-alt);

  /* Image Headliner styles */
  --image-headliner-bg-color: var(--color-bg-light);
  --image-headliner-text-color: var(--color-text);
  --image-headliner-font-weight: 400;
  --image-headliner-font-family: var(--font-sans);
  --image-headliner-link-text-color: var(--color-text);

  /* Editorial Snippet styles */
  --editorial-snippet-short-description-text-color: var(--color-text);
  --editorial-snippet-short-description-font-family: var(--font-serif);

  /* alerts */
  --alert-bg-color: var(--color-tertiary);
  --alert-text-color: var(--color-text);
  --alert-icon-color: var(--color-text);

  /* Horizontal Tabbed styles */
  --horizontal-tabbed-list-button-active-border-b-color: var(--color-brand);
  --horizontal-tabbed-list-button-active-border-b-color-light: var(--color-brand);

  /* Vertical Tabbed styles */
  --vertical-tabbed-accordion-border-color: var(--color-primary);
  --vertical-tabbed-accordion-border-color-light: var(--color-primary);
  --vertical-tabbed-accordion-icon-indicator-fill-color: var(--color-brand);
  --vertical-tabbed-accordion-icon-indicator-fill-color-light: var(--color-brand);
  --vertical-tabbed-button-inactive-bg: var(--color-bg-light);
  --vertical-tabbed-button-inactive-border-color: var(--color-primary);
  --vertical-tabbed-button-inactive-bg-light: var(--color-bg);
  --vertical-tabbed-button-inactive-border-color-light: var(--color-primary);
  --vertical-tabbed-button-active-before-bg: var(--color-brand);
  --vertical-tabbed-button-active-border-color: var(--color-primary);
  --vertical-tabbed-button-active-before-bg-light: var(--color-brand);
  --vertical-tabbed-button-active-border-color-light: var(--color-primary);
  --vertical-tabbed-panel-border-color: var(--color-primary);
  --vertical-tabbed-panel-border-color-light: var(--color-primary);
  /* dynamic grid */
  --dynamic-grid-item-header-font-family: var(--font-serif);
  --dynamic-grid-item-header-font-weight: 400;
  --dynamic-grid-item-wom-header-font-weight: 400;
}

@screen lg {
  .theme-lx {
    --dynamic-grid-item-wom-header-font-size: 1.875rem;
    --dynamic-grid-item-wom-header-line-height: 2.25rem;
  }
}

.theme-lx h1,
.theme-lx h2,
.theme-lx h3,
.theme-lx h4,
.theme-lx h5,
.theme-lx h6 {
  font-variant-ligatures: common-ligatures;
}

.theme-lx {
  /* Design System font variables */
  --ds-body-font-family: 'Avenir';
  --ds-headline-font-family: 'Clearface Serial';

  --color-slh-honey: #9e693d;
  --color-slh-toast: #f7f0ea;
  --color-slh-graphite: #282828;
  --color-slh-light-gray: #f4f4f4;
  --color-slh-yellow: #f4cc2c;

  .surface-base,
  .surface-alternate {
    --ds-color-background: var(--color-slh-light-gray);
    --ds-color-headline: var(--color-slh-graphite);
    --ds-color-interactive: var(--color-slh-graphite);
    --ds-color-interactive-hover: var(--color-black);
  }

  .surface-alternate {
    --ds-color-background: var(--color-slh-toast);
    --ds-color-border: var(--color-neutral-300);
  }

  .surface-inverse {
    --ds-color-background: var(--color-slh-graphite);
    --ds-color-interactive: var(--color-slh-yellow);
    --ds-color-interactive-hover: var(--color-neutral-50);
  }

  .btn--base {
    @apply rounded-none;
  }
}
