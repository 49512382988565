.ranked-amenities-circle {
  box-shadow: 0 2px 10px 0 rgba(0, 0, 0, 0.11);
  transform: translateX(-50%);
}

.ranked-amenities-tile {
  box-shadow: 0 2px 20px 0 rgba(0, 0, 0, 0.07);
}

.ranked-amenities-tile::before {
  content: '';
  position: absolute;
  top: -20px;
  left: 50%;
  width: 0;
  height: 0;
  border-style: none;
  border-left: 20px solid transparent;
  border-right: 20px solid transparent;
  border-bottom: 20px solid rgb(241, 241, 241);
  transform: translateX(-50%);
}
