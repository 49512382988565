@tailwind components;

@layer components {
  .surface-base,
  .surface-alternate,
  .surface-inverse {
    --ds-button-solid-foreground: initial;
    --ds-button-solid-background: initial;
    --ds-button-solid-border: initial;
    --ds-button-solid-foreground-hover: initial;
    --ds-button-solid-background-hover: initial;
    --ds-button-solid-border-hover: initial;
    --ds-button-outline-foreground: initial;
    --ds-button-outline-background: initial;
    --ds-button-outline-border: initial;
    --ds-button-outline-foreground-hover: initial;
    --ds-button-outline-background-hover: initial;
    --ds-button-outline-border-hover: initial;
    --ds-button-flat-foreground: initial;
    --ds-button-flat-background: initial;
    --ds-button-flat-border: initial;
    --ds-button-flat-foreground-hover: initial;
    --ds-button-flat-background-hover: initial;
    --ds-button-flat-border-hover: initial;
    --ds-button-accent-solid-foreground: initial;
    --ds-button-accent-solid-background: initial;
    --ds-button-accent-solid-border: initial;
    --ds-button-accent-solid-foreground-hover: initial;
    --ds-button-accent-solid-background-hover: initial;
    --ds-button-accent-solid-border-hover: initial;
    --ds-button-accent-outline-foreground: initial;
    --ds-button-accent-outline-background: initial;
    --ds-button-accent-outline-border: initial;
    --ds-button-accent-outline-foreground-hover: initial;
    --ds-button-accent-outline-background-hover: initial;
    --ds-button-accent-outline-border-hover: initial;
    --ds-button-accent-flat-foreground: initial;
    --ds-button-accent-flat-background: initial;
    --ds-button-accent-flat-border: initial;
    --ds-button-accent-flat-foreground-hover: initial;
    --ds-button-accent-flat-background-hover: initial;
    --ds-button-accent-flat-border-hover: initial;
    --ds-button-disabled-foreground: initial;
    --ds-button-disabled-background: initial;
    --ds-button-disabled-border: initial;

    color: var(--ds-color-text);
    background-color: var(--ds-color-background);
  }

  .surface-base,
  .surface-alternate {
    --ds-color-background: var(--color-white);
    --ds-color-background-contrast: var(--color-neutral-950);
    --ds-color-background-disabled: var(--color-neutral-100);
    --ds-color-border: var(--color-neutral-300);
    --ds-color-border-input: var(--color-neutral-500);
    --ds-color-headline: var(--color-neutral-900);
    --ds-color-text: var(--color-neutral-950);
    --ds-color-text-contrast: var(--color-white);
    --ds-color-text-muted: var(--color-neutral-600);
    --ds-color-text-disabled: var(--color-neutral-400);
    --ds-color-interactive: var(--color-neutral-900);
    --ds-color-interactive-contrast: var(--color-white);
    --ds-color-interactive-hover: var(--color-neutral-600);
    --ds-color-interactive-hover-contrast: var(--color-white);
    --ds-color-interactive-accent: var(--ds-color-interactive);
    --ds-color-interactive-accent-contrast: var(--ds-color-interactive-contrast);
    --ds-color-interactive-accent-hover: var(--ds-color-interactive-hover);
    --ds-color-interactive-accent-hover-contrast: var(--ds-color-interactive-hover-contrast);
  }

  .surface-alternate {
    --ds-color-background: var(--color-neutral-50);
    --ds-color-border: var(--color-neutral-500);
    --ds-color-border-input: var(--color-neutral-600);
    --ds-color-text-muted: var(--color-neutral-700);
    --ds-color-text-disabled: var(--color-neutral-500);
  }

  .surface-inverse {
    --ds-color-background: var(--color-neutral-900);
    --ds-color-background-contrast: var(--color-white);
    --ds-color-background-disabled: var(--color-neutral-500);
    --ds-color-border: var(--color-black);
    --ds-color-border-input: var(--color-neutral-700);
    --ds-color-headline: var(--color-white);
    --ds-color-text: var(--color-white);
    --ds-color-text-contrast: var(--color-neutral-950);
    --ds-color-text-muted: var(--color-neutral-400);
    --ds-color-text-disabled: var(--color-neutral-700);
    --ds-color-interactive: var(--color-white);
    --ds-color-interactive-contrast: var(--color-neutral-950);
    --ds-color-interactive-hover: var(--color-neutral-100);
    --ds-color-interactive-hover-contrast: var(--color-black);
    --ds-color-interactive-accent: var(--ds-color-interactive);
    --ds-color-interactive-accent-contrast: var(--ds-color-interactive-contrast);
    --ds-color-interactive-accent-hover: var(--ds-color-interactive-hover);
    --ds-color-interactive-accent-hover-contrast: var(--ds-color-interactive-hover-contrast);
    --ds-button-disabled-foreground: var(--color-neutral-500);
    --ds-button-disabled-background: var(--color-neutral-800);
    --ds-button-disabled-border: var(--color-neutral-800);
  }

  .link--base {
    @apply motion-safe:transition-colors;
    color: var(--ds-color-interactive);
    &:hover,
    &:focus,
    &:active {
      color: var(--ds-color-interactive-hover);
    }
  }

  .heading--base {
    color: var(--ds-color-headline);
    font-family: var(--ds-headline-font-family);
    font-weight: var(--ds-headline-font-weight);
    line-height: var(--ds-headline-line-height);
    letter-spacing: var(--ds-headline-letter-spacing);
  }

  .heading--sm {
    font-size: var(--ds-font-size-heading-sm);
  }

  .heading--md {
    font-size: var(--ds-font-size-heading-md);
  }

  .heading--lg {
    font-size: var(--ds-font-size-heading-lg);
  }

  .heading--xl {
    font-size: var(--ds-font-size-heading-xl);
  }

  .heading--2xl {
    font-size: var(--ds-font-size-heading-2xl);
  }

  .heading--3xl {
    font-size: var(--ds-font-size-heading-3xl);
  }

  .btn--brand {
    --btn-color: var(--ds-color-interactive);
    --btn-color-contrast: var(--ds-color-interactive-contrast);
    --btn-color-hover: var(--ds-color-interactive-hover);
    --btn-color-hover-contrast: var(--ds-color-interactive-hover-contrast);
    &.btn--auto,
    &.btn--solid {
      --btn-fg-color: var(--ds-button-solid-foreground, var(--btn-color-contrast));
      --btn-bg-color: var(--ds-button-solid-background, var(--btn-color));
      --btn-b-color: var(--ds-button-solid-border, var(--btn-color));
      &:hover:not(:disabled),
      &:focus:not(:disabled),
      &:active:not(:disabled),
      &:focus-within:not(:disabled) {
        --btn-fg-color: var(--ds-button-solid-foreground-hover, var(--btn-color-hover-contrast));
        --btn-bg-color: var(--ds-button-solid-background-hover, var(--btn-color-hover));
        --btn-b-color: var(--ds-button-solid-border-hover, var(--btn-color-hover));
      }
    }
    &.btn--outline {
      --btn-fg-color: var(--ds-button-outline-foreground, var(--btn-color));
      --btn-bg-color: var(--ds-button-outline-background, transparent);
      --btn-b-color: var(--ds-button-outline-border, var(--btn-color));
      &:hover:not(:disabled),
      &:focus:not(:disabled),
      &:active:not(:disabled),
      &:focus-within:not(:disabled) {
        --btn-fg-color: var(--ds-button-outline-foreground-hover, var(--btn-color-hover-contrast));
        --btn-bg-color: var(--ds-button-outline-background-hover, var(--btn-color-hover));
        --btn-b-color: var(--ds-button-outline-border-hover, var(--btn-color-hover));
      }
    }
    &.btn--flat {
      --btn-fg-color: var(--ds-button-flat-foreground, var(--btn-color));
      --btn-bg-color: var(--ds-button-flat-background, transparent);
      --btn-b-color: var(--ds-button-flat-border, transparent);
      &:hover:not(:disabled),
      &:focus:not(:disabled),
      &:active:not(:disabled),
      &:focus-within:not(:disabled) {
        --btn-fg-color: var(--ds-button-flat-foreground-hover, var(--btn-color-hover));
        --btn-bg-color: var(--ds-button-flat-background-hover, transparent);
        --btn-b-color: var(--ds-button-flat-border-hover, transparent);
      }
    }
  }

  .btn--accent {
    --btn-color: var(--ds-color-interactive-accent);
    --btn-color-contrast: var(--ds-color-interactive-accent-contrast);
    --btn-color-hover: var(--ds-color-interactive-accent-hover);
    --btn-color-hover-contrast: var(--ds-color-interactive-accent-hover-contrast);
    &.btn--auto,
    &.btn--solid {
      --btn-fg-color: var(--ds-button-accent-solid-foreground, var(--btn-color-contrast));
      --btn-bg-color: var(--ds-button-accent-solid-background, var(--btn-color));
      --btn-b-color: var(--ds-button-accent-solid-border, var(--btn-color));
      &:hover:not(:disabled),
      &:focus:not(:disabled),
      &:active:not(:disabled),
      &:focus-within:not(:disabled) {
        --btn-fg-color: var(
          --ds-button-accent-solid-foreground-hover,
          var(--btn-color-hover-contrast)
        );
        --btn-bg-color: var(--ds-button-accent-solid-background-hover, var(--btn-color-hover));
        --btn-b-color: var(--ds-button-accent-solid-border-hover, var(--btn-color-hover));
      }
    }
    &.btn--outline {
      --btn-fg-color: var(--ds-button-accent-outline-foreground, var(--btn-color));
      --btn-bg-color: var(--ds-button-accent-outline-background, transparent);
      --btn-b-color: var(--ds-button-accent-outline-border, var(--btn-color));
      &:hover:not(:disabled),
      &:focus:not(:disabled),
      &:active:not(:disabled),
      &:focus-within:not(:disabled) {
        --btn-fg-color: var(
          --ds-button-accent-outline-foreground-hover,
          var(--btn-color-hover-contrast)
        );
        --btn-bg-color: var(--ds-button-accent-outline-background-hover, var(--btn-color-hover));
        --btn-b-color: var(--ds-button-accent-outline-border-hover, var(--btn-color-hover));
      }
    }
    &.btn--flat {
      --btn-fg-color: var(--ds-button-accent-flat-foreground, var(--btn-color));
      --btn-bg-color: var(--ds-button-accent-flat-background, transparent);
      --btn-b-color: var(--ds-button-accent-flat-border, transparent);
      &:hover:not(:disabled),
      &:focus:not(:disabled),
      &:active:not(:disabled),
      &:focus-within:not(:disabled) {
        --btn-fg-color: var(--ds-button-accent-flat-foreground-hover, var(--btn-color-hover));
        --btn-bg-color: var(--ds-button-accent-flat-background-hover, transparent);
        --btn-b-color: var(--ds-button-accent-flat-border-hover, transparent);
      }
    }
  }

  .btn--sm {
    @apply text-sm;
  }

  .btn--md {
    @apply text-base;
  }

  .btn--lg {
    @apply text-lg;
  }

  .btn--base {
    @apply motion-safe:transition-colors border;
    color: var(--btn-fg-color);
    background-color: var(--btn-bg-color);
    border-color: var(--btn-b-color);
    &:disabled {
      @apply cursor-not-allowed;
      box-shadow: none;
      --btn-fg-color: var(--ds-button-disabled-foreground, var(--color-neutral-400));
      --btn-bg-color: var(--ds-button-disabled-background, var(--color-neutral-100));
      --btn-b-color: var(--ds-button-disabled-border, var(--color-neutral-100));
    }
  }
}
