/* Brand- Tru */
@font-face {
  font-family: Proxima Nova N W01 Black;
  font-display: swap;
  src: url('/modules/assets/fonts/proxima-nova/bold.woff2') format('woff2');
}

/* THEMES */
.theme-ru {
  --color-primary: 0 0 0;
  --color-primary-alt: 0 0 0;
  --color-secondary: 51 0 114;
  --color-secondary-alt: 51 0 114;
  --color-brand: 0 0 0;
  --color-brand-alt: 0 0 0;
  --font-headline: 'Proxima Nova N W01 Black';
  --color-bg-light: 241 241 241;
  --color-bg-dark: 0 119 200;
  --color-text-heading: var(--color-primary);
  --heading-spacing-preset: normal;

  /* property-ui theme */
  --color-ru-accent: 0 0 0;

  /* Image Headliner styles */
  --image-headliner-control-color-default: var(--color-text-inverse);
}

.theme-ru h1,
.theme-ru h2,
.theme-ru h3,
.theme-ru h4,
.theme-ru h5,
.theme-ru h6 {
  font-variant-ligatures: common-ligatures;
}

.theme-ru {
  /* Design System font variables */
  --ds-headline-font-family: 'Proxima Nova N W01 Black';

  --color-tru-cyan: #00aeef;
  --color-tru-yellow: #fff200;
  --color-tru-ocean: #3399cc;
  --color-tru-lime: #96ca4f;
  --color-tru-sunshine: #f2a900;
  --color-tru-wine: #ad1aad;
  --color-tru-violet: #330074;

  .surface-base,
  .surface-alternate {
    --ds-color-text: var(--color-tru-violet);
    --ds-color-headline: var(--color-tru-violet);
    --ds-color-interactive: var(--color-tru-violet);
    --ds-color-interactive-hover: var(--color-black);
  }

  .surface-alternate {
    --ds-color-background: var(--color-tru-yellow);
  }

  .surface-inverse {
    --ds-color-background: var(--color-tru-violet);
    --ds-color-headline: var(--color-tru-cyan);
    --ds-color-interactive: var(--color-tru-cyan);
    --ds-color-interactive-hover: var(--color-tru-yellow);
  }
}
